import React from "react"
import { RecoilRoot } from "recoil"
import { ReactQueryCacheProvider, QueryCache } from "react-query"
import { ThemeProvider } from "styled-components"

import theme from "./src/gatsby-theme-material-ui-top-layout/theme"

//const ReactDOM = require('react-dom');
//
//export const replaceHydrateFunction = () => {
//  return (element, container, callback) => {
//    ReactDOM.unstable_createRoot(container, {
//      hydrate: true,
//      hydrateOptions: { onHydrated: callback },
//    }).render(element)
//  }
//}
const queryCache = new QueryCache()

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <RecoilRoot>{element}</RecoilRoot>
      </ReactQueryCacheProvider>
    </ThemeProvider>
  )
}
